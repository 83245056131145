<template>
  <div>
    <app-header />
    <nav-bar />
    <div class="content">
      <v-container fluid>
        <v-row>
          <overlay-loader :loading="loading"></overlay-loader>
          <v-alert v-if="alert" class="mt-4" type="error" transition="scale-transition">{{ error }}</v-alert>
          <div class="col-md-8 embed-container">
            <!-- #zoom=85&toolbar=0&scrollbar=0 -->
            <embed v-if="data_model.title && data_model.title.endsWith('pdf')"
            v-bind:src="fileView+''" width="100%" height="2300px">
            <!-- <object v-if="data_model.title.endsWith('pdf')"
              v-bind:data="fileView"
              type="application/pdf"
              width=100%
              height="100%">
            </object> -->
            <object v-else-if="data_model.title"
              v-bind:data="fileViewImage"
              type="image/png"
              class="preview-img">
            </object>
          </div>
          <div class="col-md-4">
            <v-card align='left' class='information-style'>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-toolbar-title class="mt-3">Information</v-toolbar-title>
                  </v-col>
                  <v-col cols="12">
                    <hr>
                  </v-col>
                  <v-col cols="12" v-if="data_model.version && canUpdateDocument">
                    <update-document
                      :docId="doc_number_model"
                      :docExpired="documentExpirationBool"
                      :id="obtain_id"
                      :version="data_model.version"
                      :zone="data_model.zone.name || ''"
                      :section="data_model.section.name || ''"
                      :artifact="data_model.artifact.name || ''"
                      :study_id="data_model.study.id || 0"
                      :fileName="fileTitle"
                      @updateVersion="updateVersion">
                    </update-document>
                  </v-col>
                  <v-col cols="12" v-if="canSendToApprove">
                    <v-dialog v-model="dialog" scrollable max-width="900px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn rounded block v-bind="attrs" v-on="on" :disabled="checkAllFields" outlined>
                          <v-icon class="mr-2 mb-1 mdi-rotate-315 mdi-19px">mdi-send</v-icon>
                          {{ canSendToOtherApprover ? 'Send to other approver' : 'Send' }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>Send for approval</v-card-title>
                        <v-card-text>
                          <div>
                            <strong>Select user</strong>
                            <v-autocomplete
                              v-model="rms_model"
                              :items="rms"
                              item-text="email"
                              menu-props="auto"
                              return-object
                              label="Select"
                              underlined>
                            </v-autocomplete>
                          </div>
                          <div>
                            <strong>Commentary</strong>
                            <v-text-field v-model="commentary_model" underlined clearable></v-text-field>
                          </div>
                        </v-card-text>
                        <v-card-actions style="margin-bottom: 10px;" class="text-right">
                          <v-spacer></v-spacer>
                          <v-btn outlined rounded @click="dialog=false" color="error">Close</v-btn>
                          <v-btn
                            :disabled="checkSelectedRmsUser"
                            disable
                            outlined
                            rounded
                            @click="canSendToOtherApprover ? passApproveToOther() : saveInformation(true)">
                            Send
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row v-if="data_model.approver_user">
                  <v-col cols="12">
                    <v-dialog v-model="NoApproveClassificationDialog" width="600px">
                      <commentary-not-approved-dialog
                        :key="obtain_id"
                        :obtain_id="obtain_id"
                        :approver_first_name="data_model.approver_user.first_name"
                        :approver_last_name="data_model.approver_user.last_name"
                        @cancel="NoApproveClassificationDialog=false"
                        @loading="loading=true"
                        @loadingFinished="loading=false"
                        @confirm="NoApproveClassificationDialog=false" />
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row v-if="canBeApproved">
                  <v-col cols="12" md="6">
                        <v-btn rounded class="btn-block" block outlined @click="ApproveClassification">Approve</v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                        <v-btn rounded block outlined @click="NoApproveClassificationDialog=true">Reject</v-btn>
                  </v-col>
                </v-row>
                <v-list-group :value="true" class="mt-2">
                  <template v-slot:activator>
                    <v-list-item-title>General properties</v-list-item-title>
                  </template>
                  <v-list-item>
                    <div><strong>Document ID:</strong> {{ data_model.id }}</div>
                  </v-list-item>
                  <v-list-item>
                    <div><strong>Status: </strong>
                      <data-status-label :status_id="data_model.status.id"></data-status-label>
                    </div>
                  </v-list-item>
                  <v-list-item>
                    <div><strong>Created by:</strong> {{ data_model.user.email }}</div>
                  </v-list-item>
                  <v-list-item>
                    <div><strong>Last modified by:</strong> {{ data_model.last_user.email }}</div>
                  </v-list-item>
                  <v-list-item v-if="data_model.approver_user">
                    <div><strong>Reviewer:</strong> {{ data_model.approver_user.email }}</div>
                  </v-list-item>
                  <v-list-item class="mb-2">
                    <div><strong>Original file name:</strong> {{ data_model.name }}</div>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      class="no-border"
                      v-model="fileTitle"
                      label="File name"
                      :disabled="!editEnable"
                      underlined
                      readonly>
                    </v-text-field>
                  </v-list-item>
                  <v-list-item v-if="data_model.status.id === Constants.STATUS_REJECTED_ID
                  || data_model.status.id === Constants.STATUS_PENDING_REVIEW_ID">
                    <div><strong>{{ data_model.status.id === Constants.STATUS_REJECTED_ID
                      ? 'Rejection Comment' : 'Review comment' }}</strong> {{ data_model.commentary }}</div>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="data_model.attributes"
                      label="Attributes"
                      :disabled="!editEnable"
                      underlined>
                    </v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="data_model.clarifying_information"
                      label="Clarifying information"
                      :disabled="!editEnable"
                      underlined>
                    </v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      v-model='data_model.zone'
                      :items="zoneItems"
                      item-text="name"
                      menu-props="auto"
                      return-object
                      required
                      label="Zone*"
                      underlined
                      :disabled="!editEnable">
                      <template v-slot:item="{ item }" >{{ item.number }} {{ item.name }}</template>
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      v-model="data_model.section"
                      :items="filteredSectionItems()"
                      item-text="name"
                      menu-props="auto"
                      return-object
                      required
                      label="Section*"
                      underlined
                      :disabled="!editEnable">
                      <template v-slot:item="{ item }" >{{ item.number }} {{ item.name }}</template>
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      v-model="data_model.artifact"
                      :items="filterArtifactItems()"
                      item-text="name"
                      menu-props="auto"
                      return-object
                      required
                      label="Artifact*"
                      underlined
                      :disabled="!editEnable">
                      <template v-slot:item="{ item }" >{{ item.number }} {{ item.name }}</template>
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      class="no-border"
                      v-model="data_model.version"
                      label="Version*"
                      :disabled="!editEnable"
                      required
                      readonly
                      undefined
                      :rules="rules.name">
                    </v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      v-model="data_model.study"
                      :items="studyItems"
                      item-text="name"
                      label="Study*"
                      required
                      return-object
                      underlined
                      @change="studyChange()"
                      :disabled="!editEnable">
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      v-model="data_model.study_country"
                      :items="studyCountryList"
                      item-text="name"
                      label="Study country"
                      return-object
                      underlined
                      clearable
                      @change="studyCountryChange()"
                      :disabled="!editEnable">
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      v-model="data_model.study_site"
                      :items="studySiteList"
                      item-text="name"
                      label="Study site"
                      return-object
                      underlined
                      clearable
                      :disabled="!editEnable">
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-menu
                      v-model="menuReceivedData"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data_model.received_data"
                          label="Received date"
                          hint="yyyy/mm/dd format"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!editEnable"
                          underlined>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data_model.received_data"
                        @input="menuReceivedData = false"
                        :disabled="!editEnable">
                      </v-date-picker>
                    </v-menu>
                  </v-list-item>
                  <v-list-item>
                    <v-menu
                      v-model="menuDocDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :disabled="!editEnable">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data_model.doc_date"
                          label="doc date"
                          hint="yyyy/mm/dd format"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!editEnable"
                          underlined>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data_model.doc_date"
                        @input="menuDocDate = false"
                        :disabled="!editEnable">
                      </v-date-picker>
                    </v-menu>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      v-model="data_model.doc_language"
                      multiple
                      required
                      :items="languages"
                      label="doc language*"
                      :disabled="!editEnable"
                      :rules="rules.language"
                      @input="searchLanguage=null"
                      :search-input.sync = "searchLanguage"
                      underlined>
                    </v-autocomplete>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="data_model.information_classification"
                      label="information classification"
                      :disabled="!editEnable"
                      underlined>
                    </v-text-field>
                  </v-list-item>
                  <v-list-item>
                    <v-checkbox label="Substantial modification" v-model="data_model.is_amendment"
                    :disabled="!editEnable">
                    </v-checkbox>
                  </v-list-item>
                  <v-list-item>
                    <v-checkbox label="Urgent" v-model="data_model.urgent" :disabled="!editEnable"></v-checkbox>
                  </v-list-item>
                  <v-list-item>
                    <v-checkbox label="ISF" v-model="data_model.isf" :disabled="!editEnable"></v-checkbox>
                  </v-list-item>
                  <v-list-item>
                    <v-checkbox label="Require verified copy"
                    v-model="data_model.required_verified_copy" :disabled="!editEnable"></v-checkbox>
                  </v-list-item>
                  <v-list-item>
                    <v-checkbox label="Document expire?"
                    v-model="documentExpirationBool"
                    @change="data_model.expiration_date = null" :disabled="!editEnable"></v-checkbox>
                  </v-list-item>
                  <v-list-item v-if="documentExpirationBool">
                    <div>
                      <v-text-field
                        v-model="data_model.expiration_date"
                        type="date"
                        :disabled="!editEnable">
                      </v-text-field>
                    </div>
                  </v-list-item>
                  <v-list-item>
                    <v-slider
                      class="mt-12"
                      :value="deadlineInDays"
                      :color="deadlineColor"
                      min="0"
                      :max="data_model.urgent ? 2 : 5"
                      step="1"
                      label="Remaining days"
                      :thumb-size="24"
                      thumb-label="always"
                      readonly>
                    </v-slider>
                  </v-list-item>
                </v-list-group>
                <QualityIssues
                :qcIssueHistory="data_model.qc_issue_history"
                :qcIssueType="data_model.qc_issue_type"
                :historyDocumentsApproved="historyDocumentsApproved"/>
                <div class="save-style text-right">
                  <v-btn
                    class="mb-2 ml-2"
                    @click="saveInformation(false)"
                    :disabled="checkAllFields"
                    outlined
                    rounded
                    v-if="roleManager.canUpload() && canSave">
                    Save
                  </v-btn>
                </div>
              </v-container>
            </v-card>
          </div>
        </v-row>
        <v-row>
          <v-col>
            <audit-trail-document :headers="historyHeaders" :documents="historyDocuments" :search="search"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import AppHeader from '@/components/generic/AppHeader.vue';
import DataStatusLabel from '@/components/generic/DataStatusLabel.vue';
import NavBar from '@/components/generic/NavBar.vue';
import { accountServices } from '@/services/accountServices';
import AuditTrailDocument from '../components/AuditTrailDocument.vue';
import QualityIssues from '../components/QualityIssues.vue';
import UpdateDocument from '../components/UpdateDocument.vue';
import CommentaryNotApprovedDialog from '@/components/CommentaryNotApprovedDialog.vue';
import { masterdataServices } from '@/services/masterdataServices';
import { fileServices } from '../services/fileServices';
import { getLanguages } from '../utils/languages';
import { roleManager } from '../store/roleManager';
import { Constants } from '../store/constants';
import cookieManager from '@/store/cookieManager';

export default {
  name: 'EditData',
  components: {
    AppHeader,
    NavBar,
    OverlayLoader,
    DataStatusLabel,
    AuditTrailDocument,
    UpdateDocument,
    QualityIssues,
    CommentaryNotApprovedDialog,
  },
  data() {
    return {
      Constants,
      roleManager,
      loading: false,
      alert: false,
      error: '',
      myUserId: cookieManager.get('user_id', Number), // eslint-disable-line
      rules: {
        name: [
          (val) => (val || '').length > 0 || 'This field is required'],
        language: [
          (val) => (val || []) !== 0 || 'This field is required'],
      },
      zoneItems: [],
      sectionItems: [],
      artifactItems: [],
      studyItems: [],
      studySiteItems: [],
      studyCountryItems: [],
      obtain_id: '',
      menuReceivedData: false,
      menuDocDate: false,
      urgent: false,
      fileView: '',
      fileViewImage: '',
      historyHeaders: [
        { text: 'id', value: 'id' },
        { text: 'Event description', value: 'event_description' },
        { text: 'User', value: 'last_user' },
        { text: 'Time', value: 'historic_timestamp' },
        { text: 'Status', value: 'status_name' },
        { text: 'Approver user', value: 'approver_user' },
        { text: 'Commentary', value: 'commentary' },
      ],
      search: '',
      historyDocuments: [],
      doc_number_model: '',
      dialog: false,
      NoApproveClassificationDialog: false,
      commentary_model: '',
      rms_model: null,
      rms: [],
      languages: [],
      searchLanguage: null,
      selectedFileVersion: '',
      documentExpirationBool: false,
      data_model: {
        name: '',
        study: null,
        study_country: null,
        status: { id: 0 },
        zone: { name: '', id: 0 },
        section: { name: '', id: 0 },
        artifact: { name: '', id: 0 },
        user: {},
        last_user: {},
        doc_language: [],
        version: '1',
        expiration_date: false,
        expiration_date_object: null,
      },
    };
  },
  computed: {
    deadlineColor() {
      if (this.deadlineInDays < 1) return 'red';
      if (this.deadlineInDays < 2) return 'orange';
      return 'green';
    },
    checkAllFields() {
      return !(this.data_model !== null
      && this.data_model.status.id !== 0
      && this.data_model.version !== ''
      && this.data_model.doc_language.length !== 0);
    },
    checkSelectedRmsUser() {
      return this.rms_model === undefined || this.rms_model === null;
    },
    fileTitle() {
      // items name list
      const items = [];
      // Model vars
      const sid = this.data_model.study ? this.data_model.study.id : '';
      const ar = this.data_model.artifact;
      const attrs = this.data_model.attributes;
      const ssite = this.data_model.study_site;
      const scountry = this.data_model.study_country;
      // Process
      const artWtSpaces = ar ? ar.name.replaceAll(' ', '-') : '';
      const siteWtSpaces = (ssite && ssite.name) ? ssite.name.replaceAll(' ', '-') : '';
      const countryWtSpaces = (scountry && scountry.name) ? scountry.name.replaceAll(' ', '-') : '';
      const attrWtSpaces = (attrs !== undefined && attrs !== '') ? attrs.replaceAll(' ', '-') : '';
      let extension = this.data_model.name.split('.');
      if (extension.length > 1) {
        extension = `.${extension.pop()}`;
      } else {
        extension = '';
      }
      items.push(sid);
      if (countryWtSpaces !== '') items.push(countryWtSpaces);
      if (siteWtSpaces !== '') items.push(siteWtSpaces);
      if (artWtSpaces !== '') items.push(artWtSpaces);
      if (attrWtSpaces !== '') items.push(attrWtSpaces);
      items.push(this.data_model.doc_date);
      const result = items.join('_');

      return `${result}${extension}`;
    },
    editEnable() {
      if (this.data_model === null || this.data_model === undefined) {
        return false;
      }
      if (this.data_model.status.id !== Constants.STATUS_APPROVED_ID
      && this.data_model.status.id !== Constants.STATUS_PENDING_REVIEW_ID
      && this.data_model.status.id !== 0
      && roleManager.canUpload()) {
        return true;
      }
      return false;
    },
    canUpdateDocument() {
      if (this.data_model === null || this.data_model === undefined) {
        return false;
      }
      if (this.data_model.status.id !== Constants.STATUS_PENDING_REVIEW_ID
      && this.data_model.status.id !== 0
      && roleManager.canUpload()) {
        return true;
      }
      return false;
    },
    canSendToApprove() {
      if (this.data_model === null || this.data_model === undefined) return false;
      const statusOk = this.data_model.status.id !== Constants.STATUS_APPROVED_ID && this.data_model.status.id !== 0;
      let roleOk = roleManager.canUpload() || roleManager.isAnyAdmin();
      if (!roleManager.isAnyAdmin() && roleManager.canApprove()
      && this.data_model.status.id === Constants.STATUS_PENDING_REVIEW_ID) {
        // Check that the approver is not equal to the reviewer in the doc
        roleOk = this.data_model.approver_user && this.data_model.approver_user.id === this.myUserId;
      }
      return statusOk && roleOk;
    },
    canSendToOtherApprover() {
      if (this.data_model === null || this.data_model === undefined) return false;
      if (roleManager.isAnyAdmin() && this.data_model.status.id === Constants.STATUS_PENDING_REVIEW_ID) return true;
      if (roleManager.canUpload() && this.data_model.status.id === Constants.STATUS_PENDING_REVIEW_ID) return true;
      return roleManager.canApprove() && this.data_model.approver_user
      && this.data_model.approver_user.id === this.myUserId;
    },
    canBeApproved() {
      if (this.data_model === null || this.data_model === undefined) {
        return false;
      }
      return this.data_model.status.id === Constants.STATUS_PENDING_REVIEW_ID
      && this.data_model.status.id !== 0 && roleManager.canApprove()
      && this.data_model.user.id !== this.myUserId
      && this.data_model.approver_user !== null
      // eslint-disable-next-line
      && this.data_model.approver_user.id === this.myUserId;
    },
    canSave() {
      if (this.data_model === null || this.data_model === undefined) {
        return false;
      }
      return this.data_model.status.id !== Constants.STATUS_PENDING_REVIEW_ID
      /* && this.data_model.status.id !== Constants.STATUS_REJECTED_ID */
      && this.data_model.status.id !== Constants.STATUS_APPROVED_ID
      && this.data_model.status.id !== 0;
    },
    historyDocumentsApproved() {
      return this.historyDocuments.filter((item) => item.status_name !== Constants.STATUS_APPROVED);
    },
    deadlineInDays() {
      if (this.data_model === null || this.data_model === undefined) {
        return 0;
      }
      if (this.data_model.deadline === undefined || typeof (this.data_model.deadline) === 'string') {
        return 0;
      }
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      const diffTime = this.data_model.deadline.getTime() - today.getTime();
      return diffTime / (1000 * 3600 * 24);
    },
    studyCountryList() {
      if (!this.data_model.study) return [];
      return this.studyCountryItems.filter((item) => this.data_model.study.study_country.includes(item.id));
    },
    studySiteList() {
      if (!this.data_model.study || this.data_model.study_country === null) return [];
      return this.studySiteItems.filter(
        (site) => site.study_country.id === this.data_model.study_country.id
        && site.study.id === this.data_model.study.id
        && this.data_model.study.sites.includes(site.id),
      );
    },
  },
  async mounted() {
    this.obtain_id = this.$route.params.id;
    await this.getMasterdata();
    await this.getStudies();
    await this.getStudyCountries();
    await this.getStudySites();
    this.languages = getLanguages();
    await this.getData();
    // document.addEventListener('contextmenu', (event) => event.preventDefault());
  },
  methods: {
    /** Show error alert with a message */
    showErrorAlert(msg) {
      this.alert = true;
      this.loading = false;
      this.error = msg;
      setTimeout(() => {
        this.alert = false;
      }, 7000);
    },
    async getData() {
      fileServices.getFile(this.obtain_id)
        .then((response) => {
          this.data_model = response.data;
          this.data_model.attributes = this.data_model.attributes ? this.data_model.attributes : '';
          this.data_model.received_data = this.data_model.received_data.substr(0, 10);
          this.data_model.doc_date = this.data_model.doc_date.substr(0, 10);
          this.doc_number_model = `file_${this.$route.params.id}_${this.data_model.title}`;
          this.data_model.version = this.data_model.version === '' ? '1' : this.data_model.version;
          if (this.data_model.doc_language !== ''
            && this.data_model.status.id !== Constants.STATUS_REJECTED_ID) {
            this.data_model.doc_language = this.data_model.doc_language.split(',');
          } else if (this.data_model.status.id === Constants.STATUS_REJECTED_ID) {
            const languages = this.data_model.doc_language
              .replaceAll('[', '').replaceAll(']', '').replaceAll("'", '').replaceAll(' ', '');
            this.data_model.doc_language = languages.split(',');
          }
          this.documentExpirationBool = this.data_model.expiration_date !== ''
            && this.data_model.expiration_date !== null;

          this.getHistoric();
          this.checkFile();
          this.checkFileImage();
          this.getApproverUsers();
          // this.getFileDeadlineDays();
          this.data_model.deadline = new Date(this.data_model.deadline);
        })
        .catch(() => {});
    },
    async getMasterdata() {
      const response = await masterdataServices.getMasterdata();
      this.zoneItems = response.data.zones;
      this.sectionItems = response.data.sections;
      this.artifactItems = response.data.artifacts;
    },
    getStudies() {
      fileServices.getStudies()
        .then((response) => { this.studyItems = response.data; })
        .catch(() => {});
    },
    getStudyCountries() {
      fileServices.getStudyCountryList()
        .then((response) => { this.studyCountryItems = response.data; })
        .catch(() => {});
    },
    getStudySites() {
      fileServices.getStudySites(null, null)
        .then((response) => { this.studySiteItems = response.data; })
        .catch(() => {});
    },
    checkFile() {
      const url = process.env.VUE_APP_BASE_URL;
      this.fileView = `${url}/api/files/${this.obtain_id}/check-file/?usrid=${this.myUserId}`;
    },
    checkFileImage() {
      const url = process.env.VUE_APP_BASE_URL;
      this.fileViewImage = `${url}/api/files/${this.obtain_id}/check-file-image/?usrid=${this.myUserId}`;
    },
    filterZoneFiles(zoneId) {
      return this.zoneItems.filter((data) => data.id === zoneId)[0];
    },
    filterSectionFiles(sectionId) {
      return this.sectionItems.filter((data) => data.id === sectionId)[0];
    },
    filterArtifactFiles(artifactId) {
      return this.artifactItems.filter((data) => data.id === artifactId)[0];
    },
    studyChange() {
      this.data_model.study_country = null;
      this.data_model.study_site = null;
    },
    studyCountryChange() {
      this.data_model.study_site = null;
    },
    filteredSectionItems() {
      return this.sectionItems.filter((item) => item.zone_id === this.data_model.zone.id);
    },
    filterArtifactItems() {
      return this.artifactItems.filter((item) => item.section_id === this.data_model.section.id);
    },
    async getHistoric() {
      const response = await fileServices.getHistoric(this.obtain_id);
      this.historyDocuments = response.data.map((item) => {
        const i = { ...item };
        i.historic_timestamp = new Date(i.historic_timestamp).toLocaleString('es-ES');
        return i;
      });
      // eslint-disable-next-line
      this.historyDocuments = this.historyDocuments.sort((a, b) => a.id > b.id ? -1 : 1);
    },
    async saveInformation(toApprove) {
      this.loading = true;
      const data = {};

      if (this.fileTitle) {
        data.title = this.fileTitle;
      }
      data.file_id = this.obtain_id;
      data.attributes = this.data_model.attributes;
      data.clarifying_information = this.data_model.clarifying_information;
      data.zone = this.data_model.zone.id;
      data.section = this.data_model.section.id;
      data.artifact = this.data_model.artifact.id;
      data.version = this.data_model.version;
      data.study = this.data_model.study.id;
      data.is_amendment = this.data_model.is_amendment;
      if (this.data_model.study_country) {
        data.study_country = this.data_model.study_country.id;
      }
      if (this.data_model.study_site) {
        data.study_site = this.data_model.study_site.id;
      }
      data.isf = this.data_model.isf;
      data.urgent = this.data_model.urgent;
      data.required_verified_copy = this.data_model.required_verified_copy;

      data.expiration_date = null;
      if (this.documentExpirationBool) {
        /* const month = this.data_model.expiration_date.getMonth() + 1;
        const day = this.data_model.expiration_date.getDate();
        const year = this.data_model.expiration_date.getFullYear(); */
        data.expiration_date = this.data_model.expiration_date; // `${year}/${month}/${day}`;
      }

      if (this.data_model.received_data) {
        data.received_data = this.data_model.received_data;
      }
      if (this.data_model.doc_date) {
        data.doc_date = this.data_model.doc_date;
      }
      if (this.data_model.doc_language) {
        data.doc_language = this.data_model.doc_language.join(',');
      }
      if (this.data_model.information_classification) {
        data.information_classification = this.data_model.information_classification;
      }
      if (toApprove) {
        data.to_approve = true;
        data.user_rms1_id = this.rms_model.id;
        data.commentary = this.commentary_model;
      } else {
        data.to_approve = false;
      }
      fileServices.dataUpdateClassification(this.obtain_id, data)
        .then(() => {
          this.loading = false;
          if (toApprove) {
            setTimeout(() => {
              this.loading = false;
              this.$router.push('/home');
            }, 1000);
          } else {
            this.$router.push('/library');
          }
        })
        .catch(() => { this.loading = false; });
    },
    passApproveToOther() {
      this.loading = true;
      const data = {
        user_rms1_id: this.rms_model.id,
        commentary: this.commentary_model,
      };
      fileServices.passApproveToOther(this.obtain_id, data)
        .then(() => {
          this.loading = false;
          this.$router.push('/library');
        })
        .catch(() => { this.loading = false; });
    },
    ApproveClassification() {
      this.loading = true;
      fileServices.approveUpdate(this.obtain_id)
        .then(() => { this.loading = false; this.$router.push('/library'); })
        .catch((error) => {
          this.loading = false;
          this.showErrorAlert(error.response.data.detail);
        });
    },
    getApproverUsers() {
      accountServices.getUserApprovers(this.data_model.study.id)
        .then((response) => {
          this.rms = response.data.filter((item) => item.id !== this.data_model.user.id && this.myUserId !== item.id);
        })
        .catch(() => {});
    },
    updateVersion() {
      this.data_model.version = (parseInt(this.data_model.version, 10) + 1).toString();
    },
  },
};
</script>
<style scoped>
  .app-bar {
    background-color: #558baa;
  }
  .information-style {
    margin-bottom: 16px !important;
  }
  .preview-img {
    width: 100% !important;
    border-radius: 8px;
    margin-top: 20px;
  }
  .update-file {
    margin-left: 3rem;
    width: 400px;
  }
  .file-history {
    margin-left: 3rem;
    width: 400px;
  }
  .embed-container {
    position:relative;
  }
</style>
