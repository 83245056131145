<template>
  <v-card id="not-approved-modal-card" class="mt-0">
    <v-card-title id="not-approved-modal-card-title">
      <span id="not-approved-modal-card-span" class="text-h5">Reject file</span>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-select
              v-model="issues_selected"
              :items="MainIssues.issueItems"
              label="Quality issue"
              prepend-inner-icon="mdi-filter-variant"
              item-text="name"
              item-value="number"
              multiple
              underlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              id="field-commentary"
              v-model="issue_comment"
              label="Add a commentary*"
              underlined
              clearable
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <div class="text-right w-100">
        <v-btn id="cancel-commentary-btn" class="mr-5" outlined rounded @click="cancel()" color="error">
          Close
        </v-btn>
        <v-btn id="send-commentary-btn" outlined rounded @click="NoApproveClassification" :disabled="formValid">
          Send
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
import { fileServices } from '../services/fileServices';
import { MainIssues } from '@/utils/qualityIssues';

export default {
  name: 'CommentaryNotApprovedDialog',
  data() {
    return {
      MainIssues,
      issue_comment: '',
      issues_selected: [],
    };
  },
  props: {
    obtain_id: String,
    approver_first_name: String,
    approver_last_name: String,
  },
  created() {},
  computed: {
    approverFullName() {
      return `${this.approver_first_name} ${this.approver_last_name}`;
    },
    formValid() {
      return !(this.issue_comment !== '' && this.issues_selected.length !== 0);
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    NoApproveClassification() {
      this.$emit('loading');
      const data = {
        file_id: this.obtain_id,
        commentary: this.issue_comment,
        issues: this.issues_selected,
      };
      fileServices.noApproveUpdate(this.obtain_id, data)
        .then(() => {
          this.$emit('loading');
          this.$router.push('/library');
        })
        .catch(() => {
          this.$emit('loadingFinished');
        });
    },
  },
};
</script>
